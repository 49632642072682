<template lang="pug">
  #terms-of-service.step-component
    h2 Terms of Use &amp; User Consent to Record
    .last-updated Last updated November 9, 2021

    .tos-text-container
      .tos-text
        template(v-for="section in contents")
          h2(:id="section.id" :class="section.headerClass") {{ section.header }}
          p(v-for="paragraph in section.desc") {{ paragraph }}

    .form-field.checkbox
      input(type="checkbox" @change="scrollTextTo($event, 'specimen-collection-text', 'general-text')" v-model="userConsent" :value="userConsent" id="userConsent" name="user-consent")
      label(for="userConsent") I agree to General Terms of Use and User Consent

    .form-field.checkbox
      input(type="checkbox" @change="scrollTextTo($event, 'data-confidentiality-text', 'specimen-collection-text')"  v-model="specimenCollection" :value="specimenCollection" id="specimenCollection" name="specimen-collection")
      label(for="specimenCollection") I agree to Sample Collection and Uses

    .form-field.checkbox
      input(type="checkbox" @change="scrollTextTo($event, 'recording-text', 'data-confidentiality-text')"  v-model="dataConfidentiality" :value="dataConfidentiality" id="dataConfidentiality" name="data-confidentiality")
      label(for="dataConfidentiality") I agree to Data Confidentiality, Retention, Ownership and Use

    .form-field.checkbox
      input(type="checkbox" @change="scrollTextTo($event, null, 'recording-text')" v-model="recordingConsent" :value="recordingConsent" id="recordingConsent" name="recording_consent")
      label(for="recordingConsent") I agree to Recording Terms

    button.button(:disabled="!hasAgreed" @click="goNext()") Agree
</template>

<script>
const contents = [
  {
    header: 'General',
    headerClass: 'major-section',
    id: 'general-text',
    desc: [
      'Welcome to the terms of use (“Terms of Use”) and user consent (“Consent”) between The Kit Company, Inc. (referred to as “Kit”, “we”, “us”, or “our”), and the person on whose behalf the services described below have been requested (referred to as “you” or “your”). This Consent and Terms of Use apply to the use of www.kit.com  (including its subpages) and to Kit’s mobile application (“Kit Secure App”), collectively known as the “Kit Website”. The Kit Website and Kit’s mobile application include the provision of lab diagnostic services (the “Kit Diagnostic Tests”), which is available within the United States.',
      'Please take a minute to ensure that you understand this Consent, our Terms of Use, and our Privacy Policy, applicable to your use of the Kit Website, the Kit Secure App, and the Kit Diagnostic Tests.',
    ],
  },
  {
    header:
      'By clicking on “I Agree”, you affirmatively consent to these Terms of Use and this Consent.',
    desc: [
      'If you want to withdraw your Consent or stop using Kit’s services, please send an email to: kit.support@ro.co.',
      'We will make changes to your Consent within a reasonable timeframe. Even if you withdraw your Consent, we may need to retain your personal information, Sample(s) (defined herein) or Results (defined herein) for the satisfaction of our legal obligations or where we have a reasonable belief that we have a legitimate and legal reason to do so.',
    ],
  },
  {
    header: 'Sample Collection and Uses',
    headerClass: 'major-section',
    id: 'specimen-collection-text',
    desc: [
      'In order to use the Kit Diagnostic Tests, you will collect a diagnostic sample(s) (“Sample”) using the Kit Diagnostic Test to submit to a Kit laboratory or a third-party laboratory (Lab). The Lab will process your Sample(s) and provide testing results (“Results”) as requested by your insurance company and to determine your insurability.  You may refuse to complete the sample collection, however this will impact your ability to purchase life insurance.',
      'In order for Kit to process your Samples, you must provide correct and accurate information, as well as follow the instructions provided by Kit. Failure to provide the required information or to follow instructions may result in your Sample not being tested or can lead to inaccurate Results.',
      'All tests as reviewed and approved by an independent board-certified physician licensed in your state.',
      'The Results will be made available to your insurance company via the Kit Website and the Kit Secure App. If a laboratory determines that a Sample is not suitable for testing due to the contents of the Sample, the methodology of the Sample collection, or if Kit believes the Sample has been submitted in violation of this Consent, our Privacy Policy, or our Terms of Use, Kit reserves the right to withhold the Results of your Sample from your insurance company.',
    ],
  },
  {
    header: 'Consent to HIV Testing',
    desc: [
      'If requested by the insurance company, you understand that and give your permission for the Lab to perform testing to determine if the Sample(s) have antibodies to HIV (human immunodeficiency virus) or any other identified causative agent of AIDS (acquired immune deficiency syndrome) in your blood. You further understand that a positive result does not mean that you have AIDS, but that you have been exposed to the AIDS virus, and that antibodies to that virus are present in your blood. If your results are positive, the life insurance company may contact you and ask for the name of your physician and/or health care provider to who you may authorize disclosure of the results.  If you are infected with HIV, you are infectious to others. You should seek medical follow-up care with your personal health care provider. Positive results should be verified by your physician.',
      'HIV test results are highly reliable, but not 100% accurate. If the test gives a positive result you should consider retesting in order to confirm the result. If the test gives a negative result, there is still a small possibility you may be infected with HIV. This is most likely to happen in recently infected persons. It takes at least 4 to 12 weeks for a positive test result to develop after a person is infected, and may take as long as 6 to 12 months.',
      'Antibodies to the HIV virus are found in the blood and other bodily fluids of people who have been exposed to the virus. You do not have to have AIDS to have antibodies against HIV. The virus is spread by sexual contact with an infected person, by exposure to infected blood (as in needle sharing during intravenous drug use or, rarely, as a result of a blood transfusion), or from an infected mother to her new-born infant. The HIV antibody test is actually a series of tests performed upon your blood or other bodily fluid sample by a medically accepted procedure which is extremely reliable. The testing will be performed by a licensed laboratory.',
      'Many public health organizations have recommended that before taking an AIDS virus (HIV) antibody/antigen test, a person seek counseling to become informed concerning the implications of such tests. You may wish to consider counseling, at your expense, prior to being tested.',
      'Some blood tests, such as HIV and hepatitis, considered to be positive will be reported, if required, to your state Department of Public Health.',
    ],
  },
  {
    header: 'Data Confidentiality, Retention, Ownership and Use ',
    headerClass: 'major-section',
    id: 'data-confidentiality-text',
    desc: [
      'By sending in your Sample and agreeing to this Consent and Terms of Use, you acknowledge and agree that you have read and accepted our Privacy Policy.',
      'Kit is subject to multiple data privacy laws. Kit stores and retains data for as long as required in order to provide you with services and in accordance with state and federal laws. Lab(s) will retain your sample for as long as required to provide you with Results, and as required to be in compliance with state and federal laws.',
      'By providing Kit with a Sample, you specifically understand that you do not acquire rights or compensation to any research or future products developed by Kit or Lab(s). Your Sample and Results may be stored pursuant to Lab(s) processes and procedures and used for validation, education, and/or research purposes.  You understand and agree that Kit may de-identify your Results and aggregate this information with de-identified results from other users. De-identification means that the personally identifiable information associated with your Results will be removed. Kit (1) may not disclose, sell and otherwise commercialize your Results (2) may disclose, sell and otherwise commercialize de-identified aggregated information without restriction.',
      'The Results provided by Kit Diagnostic Tests will be shared with your insurance company, who is covering the cost of the Kit Diagnostic Tests.  The Kit Diagnostic Tests may include biomarker or other testing that may reveal sensitive or personal information about your health. By signing this agreement, you agree to share the Results of your Kit Diagnostic Test with your insurance company.',
      'The Genetic Information Nondiscrimination Act of 2008 (GINA) generally makes it illegal for health insurance companies, group health plans, and most employers to discriminate against you based on your genetic information. There are currently no United States federal laws that prohibit genetic discrimination by companies that sell life insurance, disability insurance, or long-term care insurance, which may be governed by state law. GINA also does not protect you against discrimination if you have already been diagnosed with the genetic disease. You acknowledge that there are limitations to the protections afforded by this law and that the law and its protections may change in the future.',
      'By clicking on this User Consent, you acknowledge that you have read and understand our Privacy Policy and our Terms of Use. You agree that Kit and Lab(s) are not responsible or liable for the unauthorized release of Results unless the unauthorized release was the result of gross negligence or willful misconduct on the part of Kit or Lab(s).',
    ],
  },
  {
    header: 'Data Collection',
    desc: [
      'When you create your account with Kit, we will only use the information provided by you or on your behalf to assist us in providing the Kit Diagnostic Test that you, or your insurance company, have requested.',
      'We may collect personal information from which you can be identified, such as your name, date of birth, postal and e-mail address, phone number, and/or national identifier or social security number (as applicable).  We will share your personal information with your insurance company and only in the ways that are described in Kit’s Privacy Policy.',
    ],
  },
  {
    header: 'Testing',
    desc: [
      'Kit has implemented rigorous safeguards to ensure that the Kit Diagnostic Tests provide accurate Results.  By signing this agreement, you are indicating that you understand that, as with all lab testing, there is a chance of false positive or false negative results. There are many potential sources of error, including but not limited to, the failure to identify a biomarker, a sample confusion, insufficient sample size, contaminated samples, or technical laboratory errors.',
    ],
  },
  {
    header: 'Limitations',
    desc: [
      'You understand that you are not entering into a doctor-patient relationship with Kit, the Physician, or your insurance company,  and that any questions or required follow up shall be my responsibility to arrange with your own physician.',
      'You understand that Kit Diagnostic Tests and the Results of the Kit Diagnostic Tests are for information and educational use only, and to provide information to your insurance company.  Kit does not practice medicine and our services are not intended to replace medical advice or treatment. Kit is not responsible for any professional advice rendered by a healthcare professional or physician based on the Results of a Kit Diagnostic Sample. Any information and content provided are for informational purposes only and are not a substitute for medical advice, diagnosis, or treatment. By accepting this Consent, you acknowledge that the Results will not be used for diagnostic purposes, treatment, and are not a substitute for medical advice.',
      'Kit and/or Lab are not responsible or liable for decisions made by your insurance company based on the Results derived from the Kit Diagnostic Test.',
    ],
  },
  {
    header: 'Risks',
    desc: [
      'In order to utilize Kit’s Diagnostic Tests, you will need to collect the appropriate Samples. Kit’s Diagnostic Tests require the collection of a blood sample, using Kit’s proprietary technology and accompanying detailed instructions. Although the risks associated with blood draw are low, when drawing a blood sample you may be at risk for excessive bleeding, fainting, feeling light-headed, bruising, hematoma (blood accumulating under the skin), or infection (a slight risk any time the skin is broken). If you have a history of excessive bleeding or fainting while having blood work done, Kit advises avoiding any testing that requires self blood collection.',
    ],
  },
  {
    header: 'Restrictions',
    desc: [
      'By sending in your Sample and agreeing to this Consent and Terms of Use, you acknowledge and agree that will not reverse engineer, decompile, or disassemble the Kit Diagnostic Tests, the Kit Secure App or the Kit Website, except and only to the extent such activity is expressly permitted by applicable law notwithstanding this limitation.',
    ],
  },
  {
    header: 'Recording',
    id: 'recording-text',
    headerClass: 'major-section',
    desc: [
      'For the purposes of and in order to provide you with services using Kit Diagnostic Tests, Kit may audio and video record your interactions with the Website or Secure App. By clicking on “I agree” below, you consent to Kit recording your interaction. Any recording shall be compliant with  applicable law (including, as applicable, California’s Invasion of Privacy Act and similar laws in other jurisdictions).',
    ],
  },
]

export default {
  props: {},

  data() {
    return {
      contents: contents,
      userConsent: false,
      specimenCollection: false,
      dataConfidentiality: false,
      recordingConsent: false,
    }
  },

  computed: {
    hasAgreed,
  },

  watch: {},

  methods: {
    goNext,
    scrollTextTo,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function hasAgreed() {
  return (
    this.userConsent && this.specimenCollection && this.dataConfidentiality && this.recordingConsent
  )
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function goNext() {
  this.$emit('complete')
}

function scrollTextTo(event, nextID, currentID) {
  if (event.target.checked) {
    if (nextID) {
      document.getElementById(nextID).scrollIntoView({ behavior: 'smooth' })
    }
  } else {
    document.getElementById(currentID).scrollIntoView({ behavior: 'smooth' })
  }
}
</script>
